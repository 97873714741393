<template>
  <section>
    <b-modal
        id="cari-edit"
        ok-only
        hide-footer
        centered
        size="lg"
        header-bg-variant="white"
        @hidden="editHide"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <h3>
              <b-badge variant="primary" class="modalBadge">
                Cari Bilgileri
              </b-badge>
            </h3>
          </b-col>
          <b-col cols="6" class="mt-1">
            <b-form-group label="İsim" label-for="basicInput">
              <b-form-input v-model="adi" id="basicInput"/>
            </b-form-group>
          </b-col>
          <b-col cols="6" class="mt-1">
            <b-form-group label="Soyisim" label-for="soy-isim">
              <b-form-input v-model="soyAdi" id="soy-isim"/>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Adres" label-for="basicInput">
              <b-form-input v-model="adres" id="basicInput"/>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="E-Posta" label-for="basicInput">
              <b-form-input v-model="ePosta" id="basicInput"/>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Telefon" label-for="basicInput">
              <b-form-input v-model="telefon" id="basicInput"/>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="İl"
            >
              <model-list-select
                  class=""
                  :list="json"
                  v-model="il"
                  option-value="il_adi"
                  option-text="il_adi"

                  name="unvan"
                  placeholder="İl Seçiniz"
              >
              </model-list-select>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="İlçe"
            >
              <model-list-select
                  class=""
                  :list="ilceler"
                  v-model="ilce"
                  option-value="ilce_adi"
                  option-text="ilce_adi"

                  placeholder="İlçe Seçiniz"
              >
              </model-list-select>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Bakiye" label-for="basicInput">
              <b-form-input :step="priceStep" v-model="bakiye" id="basicInput"/>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Cari Not" label-for="basicInput">
              <b-form-input v-model="cariNot" id="basicInput"/>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <h3>
              <b-badge variant="success" class="modalBadge">
                Fatura Bilgileri
              </b-badge>
            </h3>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="Firma Ünvanı"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input v-model="firmaUnvan" id="basicInput"/>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="Vergi No / TC NO"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input v-model="vergiNumarasi" id="basicInput"/>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="Banka"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input v-model="banka" id="basicInput"/>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="İBAN"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input v-model="ibann" id="basicInput"/>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="Vergi Dairesi"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input v-model="vergiDairesi" id="basicInput"/>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="d-flex justify-content-end">
            <b-button
                @click="cariUpdate"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="bgInfo"
            >
              Güncelle
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </section>
</template>

<script>
import {
  BModal,
  BFormSelect,
  BInputGroupAppend,
  BPagination,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BButton,
  BCardHeader,
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import vMultiselectListbox from 'vue-multiselect-listbox-v2'
import 'vue-multiselect-listbox-v2/dist/vue-multi-select-listbox.css'
import 'vue-multiselect-listbox-v2/src/scss/vue-multi-select-listbox.scss'
import axios from 'axios'

export default {
  setup() {

    const json = require('@/assets/turkey-cities.json').data
    const ilceler = []
    return {
      json,
      ilceler,
    }
  },
  props: ['adi', 'soyAdi', 'adres', 'ePosta', 'telefon', 'il', 'ilce', 'bakiye', 'cariNot', 'firmaUnvan', 'vergiNumarasi', 'banka', 'ibann', 'vergiDairesi', 'ilceler'],
  components: {
    vMultiselectListbox,
    BModal,
    BFormSelect,
    BInputGroupAppend,
    BPagination,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
    ModelListSelect,
  },
  data() {
    return {
      cardName: 'cari-liste-card',
      grupList: [],
    }
  },
  computed: {
    listGet() {
      return Object.values(store.getters.CARI_CARD_GET)
    },
    roleControl() {
      return true
      // return store.getters.getRole === '1'
    },
  },
  watch: {
    il(newVal) {
      if (newVal) {
        // this.ilceler = this.json.find(x => x.il_adi === newVal).ilceler
      }
    },
  },
  methods: {
    editHide() {
      // const t = this
      // t.cariCardID = ''
      // t.adi = ''
      // t.soyAdi = ''
      // t.adres = ''
      // t.ePosta = ''
      // t.telefon = ''
      // t.firmaUnvan = ''
      // t.vergiNumarasi = ''
      // t.vergiDairesi = ''
      // t.il = ''
      // t.ilce = ''
      // t.bakiye = ''
      // t.cariNot = ''
      // t.banka = ''
      // t.ibann = ''
    },
    cariUpdate() {
      var guncellenenKeyler = {}
      Object.entries(this.degismeyenData)
          .forEach(entry => {
            const [key, value] = entry
            if (this.editCari[key] && this.editCari[key] != value) {
              guncellenenKeyler[key] = this.editCari[key]
            }
          })
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken)
      fd.append('serviceName', 'cari')
      fd.append('methodName', 'cariDetay')
      fd.append('islem', 'cariDuzenle')
      fd.append('cariKartID', this.editCari.cariCardID)
      Object.entries(guncellenenKeyler)
          .forEach(entry => {
            const [key, value] = entry
            fd.append(`updateList[${key}]`, value)
          })
      axios.post(store.state.POST_URL, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            this.$bvModal.hide('cari-edit')
            store.commit('setToken', res.data.userToken)
            store.commit('CARI_CARD_ACTION', res.data)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: JSON.stringify(res.data.result.errMsg)
                    .slice(1, -1),
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
            store.dispatch('cariCardlar', {
              searchKey: this.filter,
              uzunluk: this.perPage,
              baslangic: 0,
            })
                // eslint-disable-next-line no-shadow
                .then(res => {
                  this.totalRows = res.toplamKayit
                })
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err.data)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
  },
  created() {
  },
  directives: {
    Ripple,
  },
}
</script>
